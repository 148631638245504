<template>
  <div class="p-wrapper mobile">
    <div class="p-content">
      <div class="markdown-body">
        <h1 class="title"><strong>隐私政策</strong></h1>
        <p>本版更新时间：2024年5月24日</p>
        <p>
          欢迎您使用和接受【上海域起网络科技有限公司】（包括但不限于其相关工作室，以下统称“公司”或“我们”）提供的产品和服务（以下统称“公司服务”）。您在使用公司服务时，我们可能会收集和使用您的相关信息。我们承诺严密保护使用我们的产品和服务之用户（以下称“用户”或“您”）的个人信息和隐私安全。我们希望通过本隐私政策向您说明我们在收集和使用您个人信息时对应的处理规则等相关事宜，以便更好地保障您的权利。</p>


        <p>
          本《隐私政策》仅适用于公司在中国大陆地区，面向中国大陆用户发行的游戏产品及提供的服务。除非用户已阅读并接受本隐私政策，否则用户无权使用公司游戏及服务，用户使用公司游戏及服务即视为用户已阅读并同意签署本隐私条款。</p>

        <p>
          <strong>若您是未满18周岁的未成年人，您需要在您的监护人的陪同下阅读本隐私政策，请您和您的监护人务必仔细阅读；请您在取得您的监护人对本隐私政策全部条款的同意之后使用我们提供的游戏及服务。</strong>
        </p>

        <p><strong>未成年人特别说明：</strong></p>
        <p><strong>若您是未满14周岁的未成年人（以下简称“未成年人”），请通知您的监护人共同阅读本《隐私政策》，特别是《未成年人隐私保护指引》。请您在取得您的监护人对本隐私政策全部条款，特别是《未成年人隐私保护指引》全部条款的同意之后使用我们提供的游戏及服务。您点击同意本隐私条款，或您使用/继续使用我们提供的游戏及服务，或提交个人信息，都表示您已经获得您的监护人的许可，且您的监护人亦同意接受本疑似条款，特别是《未成年人隐私保护指引》的约束。</strong>
        </p>

        <p>如我们提供的某一单项服务不适用本《隐私政策》的，该服务中会以适当方式明示排除适用本《隐私政策》。
          如特定条款与本《隐私政策》条款存在同类条款约定不一致的情形，则在特定条款约束范围内应以特定条款为准。</p>

        <p>
          我们将不时更新隐私政策，因此有关的措施会随之变化。我们恳请您定期光顾本页面，以确保对我们《隐私政策》最新版本始终保持了解。在阅读完本政策之后，如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，请通过【shichang@gg.com】联系我们，我们的公司地址是【中国（上海）自由贸易试验区芳春路400号1幢3层】。</p>


        <p>目录</p>
        <p>一、收集和使用个人信息的范围、方式和目的</p>
        <p>二、如何收集和使用信息</p>
        <p>三、如何使用COOKIES技术</p>
        <p>四、可能分享、转让和披露的信息</p>
        <p>五、如何保留、储存和保护信息</p>
        <p>六、如何管理您的信息</p>
        <p>七、第三方服务</p>
        <p>八、个人信息跨境转移</p>
        <p>九、未成年人保护</p>
        <p>——《未成年人隐私保护指引》</p>
        <p>十、通知和修订</p>
        <p>十一、争议解决及联系方式</p>

        <p><strong> 一、收集和使用个人信息的范围、方式和目的</strong></p>
        <p>（一）按照法律法规及相关主管部门的要求，游戏用户需进行实名认证以使用游戏服务。我们会在获得您同意或您主动提供的情况下收集您的真实<strong>身份信息，包括姓名、身份证信息、军官证信息、护照信息、手机号等。您提供的实名身份信息将与权威数据平台数据源进行比对，如对比结果一致，方通过实名认证。未通过实名认证的用户将无法登录使用公司游戏。</strong>该信息属于敏感信息，拒绝提供<strong>实名身份信息</strong>会导致您无法使用我们提供的游戏及其他服务。
        </p>

        <p>
          （二）为了将您标注为我们的用户以及处理账号归属纠纷，如果您使用微信、QQ或发行渠道账号关联登录游戏，我们会收集您微信、QQ或发行渠道账号对应的唯一标记（包括账号昵称、头像、地区、性别）和您主动填写的其他信息（如邮箱、生日、籍贯、性别、兴趣爱好），用于保存您的登录信息，以便您在不同设备登录以及方便您分享游戏内容。如您拒绝授权此类信息，则您将无法使用第三方平台的账号登录我们的游戏，但不影响其他服务的正常使用。</p>

        <p>
          （三）为保障您正常使用我们网络的游戏服务，确保游戏服务功能的正常运行，优化游戏产品体验，保障您的账号安全，在您注册或使用我们游戏服务时，我们会使用MSA移动安全联盟软件开发工具包等工具收集您的个人常用设备信息、网络身份识别信息、后台进程、网络环境信息、包括设备ID（包括ios广告标识符（IDFA）、国际移动设备识别码（IMEI）、匿名设备标识符（OAID）、国际移动用户识别码（IMSI）、Android设备唯一标识（android_id）、全局唯一标识符（GUID）、设备类型和版本、系统版本、IP地址、MAC地址、应用ID、网络类型等）信息。</p>


        <p>
          （四）在您使用我们游戏服务的过程中，我们会收集您的系统日志信息、游戏日志信息、包括上网记录、登录日志、商店购买日志、活动日志、公会日志、升级日志、聊天日志，以便您能够在客户端查到您的游戏历史记录。上述信息同时会用于游戏运营统计分析、客服投诉处理、游戏反作弊分析，以及游戏体验优化提升。
        </p>
        <p>
          （五）如您复制游戏内信息进行分享，或在产品内接收被分享的信息的，我们需要在设备本地访问您的剪切板，读取其中的口令、兑换码和其他相关的信息，用于实现福利获取、活动参与和分享等功能。我们仅会在需要我们服务的场景读取相关的信息完成校验，且不会存储该等信息。我们不会收集您的剪切板中的其他无关信息。
        </p>


        <p>（六）当您在我们游戏中进行充值、支付时，我们会收集您的<strong>充值记录、消费记录</strong>、余额、优惠券、游戏兑换码信息，以便您查询交易记录，以及在交易出现争议时，为客服人员提供信息以支持争议诉求的处理。<strong>充值记录、消费记录</strong>属于敏感信息，收集上述信息为实现我们游戏产品的消费功能所必需，否则将无法完成交易。同时，为了尽最大程度保护您的财产安全，我们会收集支付时的设备信息、网络身份识别信息和网络环境信息，以对高风险行为进行识别、二次确认或阻断。
        </p>

        <p>
          （七）为了保障您的游戏账号安全，营造公平、健康及安全的游戏环境，我们会收集您的<strong>用户名、密码、短信、通讯录、</strong>设备信息、网络身份识别信息、游戏应用信息、操作系统、网络环境信息、游戏崩溃记录，以用于打击破坏游戏公平环境或干扰、破坏游戏服务正常进行的行为（如外挂作弊等）。
        </p>

        <p>
          （八）我们会获取您设备的外部储存和读取权限，以用于登录信息缓存、自动登录。我们会获取您的位置信息，以用于异地登录判断等反盗号规则。
        </p>

        <p>
          （九）当您在我们游戏或网站中咨询我们客服时，我们会收集您的<strong>充值记录、身份证信息、军官证信息、护照信息、手机号码、邮箱、</strong>游戏区服、游戏昵称等，获取您<strong>设备的拍照、相册权限</strong>，以用于上传账号所属证明、判断您的账号归属、确保您的账号安全。
        </p>

        <p>
          （十）当您在游戏中通过文字、图片、语音、视频及其他方式与其他玩家进行互动，我们可能会收集并保存您发送的上述信息内容用于过滤色情、暴力、政治、辱骂、恶意广告等不当内容，以此净化游戏环境，维护健康的上网环境。
        </p>

        <p>（十一）如您希望与附近的游戏玩家互动，在您授权同意后，我们会收集您的<strong>地理位置信息</strong>，用于寻找附近的游戏玩家，以便您与附近的玩家匹配、组队等。<strong>地理位置信息</strong>属于敏感信息，拒绝提供该信息只会导致您无法实现与附近的游戏玩家互动，但不影响您正常使用我们游戏的其他功能，您也可以随时取消您的地理位置信息授权。
        </p>

        <p>
          （十二）如您希望通过图片、语音、视频与其他游戏玩家互动、参与直播，在您授权同意后，游戏会访问您的<strong>照片或存储文件、麦克风、摄像头</strong>，为您提供截图保存、图片上传、语音聊天、直播互动等功能。如您拒绝授权此类信息，则您将无法使用第三方平台的账号登录我们的游戏，但不影响其他服务的正常使用。
        </p>

        <p>（十三）为保障游戏安全稳定运行并实现特定功能，部分游戏可能会接入第三方开发的软件工具开发包（Software Development
          Kit，SDK）。我们会对游戏接入的涉及个人信息收集的SDK进行安全监测，以保护您的个人信息安全。具体情况请见
          <router-link to="/thirdPartySdk">《第三方SDK目录》</router-link>
          。
        </p>

        <p>（十四）根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意：</p>
        <p>1)与我们履行法律法规规定的义务相关的；</p>
        <p>2)与国家安全、国防安全等国家利益直接相关的；</p>
        <p>3)与公共安全、公共卫生、公共知情等重大公共利益直接相关的；</p>
        <p>4)与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>5)出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；</p>
        <p>6)所收集的个人信息是您自行向社会公众公开的；</p>
        <p>7)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>8)根据您要求签订和履行合同所必需的；</p>
        <p>9)用于维护我们提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
        <p>10)为开展合法的新闻报道所必需的；</p>
        <p>
          11)出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>12)法律法规规定的其他情形。</p>

        <p>
          （十五）请您理解，我们向您提供的功能和服务是不断更新和发展的，如某一功能或服务需要收集上述信息之外您的其他种类信息，我们会通过推送通知、交互流程、网站公告、更新隐私政策等方式另行向您说明信息收集的内容、范围和目的并在征得您的同意之后再进行收集。</p>

        <p><strong>二、如何收集和使用信息</strong></p>
        <p>在您使用我们的服务过程中，我们会在<strong>您同意或授权前提下</strong>，按照如下方式收集您的下列个人信息，用以向您提供、优化我们的服务以及保障您的账户安全（<strong>获取玩家权限/个人信息一栏中，加粗信息为敏感信息</strong>）：
        </p>
        <table>
          <tr>
            <td width="15%"><p><strong>场景</strong></p></td>
            <td width="15%"><p><strong>目的</strong></p></td>
            <td width="15%"><p><strong>收集方式</strong></p></td>
            <td width="45%"><p><strong>获取玩家权限/个人信息</strong></p></td>
          </tr>
          <tr>
            <td><p>注册/登录</p></td>
            <td><p>注册账号登录信息保存完善网络身份标识</p></td>
            <td><p>app收集</p></td>
            <td>
              <p>1、网络身份标识信息</p>
              <p>2、个人常用设备信息</p>
              <p>3、第三方授权登录时，获取三方平台的注册信息（头像、昵称、以及授权的其他信息）</p>
            </td>
          </tr>
          <tr>
            <td><p>实名身份认证</p></td>
            <td><p>严格按照相关政策执行</p></td>
            <td><p>用户提供</p></td>
            <td><p><strong>1、真实身份证信息（姓名、身份证号）、面部特征等生物识别信息等个人敏感信息</strong></p></td>
          </tr>
          <tr>
            <td><p>账号绑定</p></td>
            <td><p>提升账号安全性必要时发送服务相关通知</p></td>
            <td><p>用户提供</p></td>
            <td><p><strong>1、手机号</strong></p>
              <p><strong>2、电子邮箱地址</strong></p></td>
          </tr>
          <tr>
            <td><p>游戏运行中</p></td>
            <td><p>游戏行为记录游戏运营分析客服投诉处理其他游戏安全分析工作</p></td>
            <td><p>app收集</p></td>
            <td>
              <p>1、存储权限</p>
              <p>2、收集游戏使用详情</p>
            </td>
          </tr>
          <tr>
            <td><p>游戏运行中</p></td>
            <td><p>复制分享功能</p></td>
            <td><p>app收集</p></td>
            <td><p>1、剪切板信息</p></td>
          </tr>
          <tr>
            <td><p>游戏运行中</p></td>
            <td><p>识别账号异常状态了解产品适配性保障游戏安全运行</p></td>
            <td><p>app收集</p></td>
            <td><p>
              1、设备信息（SDK版本、设备厂商、设备型号、设备名称、操作系统类型、音量、分辨率、IP地址、应用包名及版本、设备表示符（mac/imei/android
              id/guid/imsi）、匿名设备标识符（oaid）、WiFi状态信息、电池使用情况）</p>
              <p>2、通过GET_TASKS权限检索您的应用进程</p>
              <p>3、通过READ_LOGS权限读取系统日志文件</p>
              <p>4、收集网络接入方式和类型</p>
              <p>5、应用安装列表</p>
              <p>6、游戏崩溃记录信息</p>
            </td>
          </tr>
          <tr>
            <td><p>自定义头像</p></td>
            <td><p>实现玩家更换头像需求</p></td>
            <td><p>app收集</p></td>
            <td>
              <p>1、获取访问<strong>相册或储存文件夹、摄像头权限</strong></p>
              <p>2、收集您上传的图片信息</p>
            </td>
          </tr>
          <tr>
            <td><p>账号本地存储</p></td>
            <td><p>用户二次默认登录方便用户账号找回</p></td>
            <td><p>app收集</p></td>
            <td><p>1、获取玩家读写及写入外置存储器权限</p></td>
          </tr>
          <tr>
            <td><p>兑换</p></td>
            <td><p>发放或寄送兑换奖励</p></td>
            <td><p>用户提供</p></td>
            <td><p>1、<strong>收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、支付状态</strong></p></td>
          </tr>
          <tr>
            <td><p>充值</p></td>
            <td><p>处理玩家突发充值问题</p></td>
            <td><p>app收集</p></td>
            <td><p>1、收集您的<strong>充值记录、消费记录</strong>、余额、优惠券、游戏兑换码信息</p></td>
          </tr>
          <tr>
            <td><p>客诉</p></td>
            <td><p>解决相关游戏投诉与售后服务</p></td>
            <td><p>app收集</p></td>
            <td><p>1、收集与交易进度相关的您的<strong>交易、支付、物流信息</strong>，或将您的交易信息共享给上述服务提供者
            </p></td>
          </tr>
        </table>
        <p>以上信息如有列举不全，详见本政策“ 收集和使用个人信息的范围、方式和目的”章节。</p>

        <p><strong>三、如何使用COOKIES技术</strong></p>
        <p>我们或我们的第三方合作伙伴可能通过COOKIES及相关技术收集和使用您的信息，并将该等信息储存。</p>

        <p>（一）COOKIES</p>

        <p>COOKIES
          是由用户访问的网站向浏览器发送的一小段文本，它通常包含标识符、站点名称以及一些号码和字符。它帮助浏览器记录有关访问活动的信息，例如会话信息、首选语言和其他一些设置，它是一种让网站服务器将数据存储于客户端或从客户端中读取数据的中立技术。</p>

        <p>COOKIES的使用</p>
        <p>COOKIES内容通常均经过加密，安全的COOKIES技术会为用户在上网时提供更为便捷的服务。</p>

        <p>
          COOKIES能帮助我们确定您连接的页面和内容，您在我们特定服务上花费的时间和您所选择的我们服务，您可查阅您浏览器附带的文件以获得更多这方面的信息。</p>

        <p>我们使用自己的COOKIES及相关技术，用于以下用途：</p>
        <p>（1）记住您的身份。</p>
        <p>COOKIES及相关技术有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；</p>
        <p>（2）分析您使用我们服务的情况。</p>
        <p>我们可利用COOKIES及相关技术来了解您使用我们服务进行什么活动或哪些服务最受欢迎；</p>


        <p><strong>四、可能分享、转让和披露的信息</strong></p>
        <p>（一）分享</p>
        <p>我们非常重视保护您的个人信息。
          我们仅会出于合法、正当、必要、特定、明确的目的向第三方提供您的个人信息。接收我们提供个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。 </p>

        <p>
          1、为实现本政策中声明的目的，我们可能会接入第三方服务商提供的SDK或其他类似的应用程序（如以嵌入代码、插件、H5等形式）与第三方合作，以便向您提供更好的客户服务和用户体验。为实现我们服务而可能委托处理或共享个人信息的情形如下： </p>
        <p>（1）用于广告相关服务，包括广告展示、广告数据监测/统计等；</p>
        <p>（2）用于支付相关服务，包括订单支付、交易行为核验、收入结算、支付信息汇总统计等；</p>
        <p>（3）用于在您同意的情况下获取设备位置权限、搜集设备信息和日志信息等；</p>
        <p>（4）用于第三方授权服务，包括我们游戏认可的第三方账号登陆等；</p>
        <p>（5）用于支持产品功能模块，包括在线直播、视频播放、语音识别、AR、智能客服、弹幕播放、内容存储等；</p>
        <p>
          （6）用于优化产品性能和服务质量，包括提高硬件配网能力、降低服务器成本、功能热修复、安全分析、优化游戏服务、提升用户体验等；</p>
        <p>（7）用于账号安全、产品加固相关服务，包括网络监测、域名解析、防劫持、反垃圾反作弊、加解密服务等；</p>
        <p>（8）用于物流服务，包括实物商品打包出库、运输、快递等。</p>

        <p>2、我们游戏服务可能接入或链接至非我们游戏的其他产品或服务或第三方提供的产品或服务。包括：</p>
        <p>
          （1）您可利用“分享”功能将某些我们的游戏服务内容分享到非公司游戏的其他产品或服务或第三方产品或服务，或您将非公司游戏的其他产品或服务或第三方产品或服务的内容分享到公司游戏服务；</p>
        <p>（2）通过广告或其他方式向您提供链接，使您可以链接至非公司游戏的其他产品或服务、第三方的产品或服务服务或网站；</p>
        <p>
          （3）其他接入第三方服务的情形。例如，我们接入的部分第三方SDK或类似应用程序可能会收集您的个人信息，如您在我们的服务中使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息。我们会评估这类第三方服务收集个人信息的合法性、正当性、必要性，要求该等第三方对您的个人信息采取保护措施，并严格遵守相关法律法规与监管要求。</p>

        <p>
          以上第三方服务由相关的第三方负责运营。您使用该等第三方服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及个人信息保护相关规则（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如您发现这些第三方服务存在风险时，建议您终止相关操作以保护您的合法权益并及时与我们取得联系。 </p>
        <p>您可以点击
          <router-link to="/thirdPartySdk">《第三方SDK目录》</router-link>
          ，了解我们接入的第三方服务具体情况。您也可以选择通过本政策“争议解决及联系方式”章节所列的反馈渠道联系我们，我们会尽快为您作出解答。
        </p>

        <p>
          3、为了更好地保护未成年人身心健康，引导未成年人健康上网，防止未成年人冒用、借用成年人的相关信息，我们将基于未成年人监护人的请求或我们主动保护未成年人的需要，尝试联系其监护人，将其相关的游戏账号、游戏时长、消费交易记录、游戏行为等信息告知其监护人，进行提醒、确认和处理。</p>

        <p>
          4、为提升我们游戏的产品研发能力和服务水平，以便为您提供更优质的产品和服务，我们会在合法、合理、必要的范围内，与研究机构、科研院所、高校等机构共享经去标识化或匿名化处理后的数据。未经您的明确授权，我们不会向上述机构共享可用于识别您个人身份的信息。</p>

        <p>（二）转让</p>
        <p>
          1、随着我们业务的持续发展，公司有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您信息的公司、组织继续受本《隐私政策》的约束，否则，我们将要求该公司、组织重新向您征求授权同意。 </p>
        <p>2、在获得您的明确同意后，我们会向其他方转让您的信息。</p>

        <p>（三）披露</p>
        <p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，披露您的信息：</p>
        <p>1、获得您的明确同意；</p>

        <p>2、我们将您的游戏数据（包括您的昵称、荣誉称号、段位、头像、头衔等）用于游戏内的排行榜，展示给其他玩家；</p>
        <p>3、若您在使用我们游戏服务的过程中因违反《用户协议》或存在任何违法违规行为而受到处理，我们可能会公告处理结果，并在公告中披露相关账号信息。
        </p>

        <p>（四）例外情况</p>
        <p>1、您理解并同意，在以下情形中，我们共享、转让、公开披露您的个人信息无需征求您的授权同意：</p>
        <p>（1）与公司履行法律法规规定的义务相关的；</p>
        <p>（2）与国家安全、国防安全直接相关的；</p>
        <p>（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>（4）与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>（5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；</p>
        <p>（6）您自行向社会公众公开的个人信息；</p>
        <p>（7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>

        <p><strong>五、保留、储存和保护信息</strong></p>
        <p>我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的必要时限内保留您的信息。</p>


        <p>
          我们在中华人民共和国境内运营中收集和产生的信息，存储在中国境内（港澳台除外）。确有必要进行数据的跨境传输的，我们会单独向您明确告知数据出境的目的、接收方、使用方式与范围、使用内容、安全保障措施、安全风险等，并征得您的同意。</p>

        <p>我们将采取以下手段保护您的信息：</p>
        <p>（一）数据安全技术措施</p>
        <p>
          我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的信息，采取加密技术对您的信息进行加密保存，并通过隔离技术进行隔离。
          在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中的安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。</p>

        <p>（二）我们为保护信息采取的其他安全措施。我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。
          我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
          加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。</p>


        <p>
          （三）我们仅允许有必要知晓这些信息的公司员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。</p>


        <p>（四）我们会采取一切合理可行的措施，确保未收集无关的信息。</p>


        <p>
          （五）互联网并非绝对安全的环境，且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。</p>


        <p>
          （六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非法授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>


        <p>（七）安全事件处置</p>
        <p>在通过公司服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。
          为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
          在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
          请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入我们服务所用的系统和通讯网络，有可能因公司可控范围外的情况而发生问题。</p>


        <p><strong>六、如何管理您的信息</strong></p>
        <p>（一）查询、复制及修改</p>
        <p>
          您有权对您已提供给我们的信息进行查询和复制。若您在查询的过程中发现存在登记错误、不完整的或有更新的，我们鼓励您及时更新和修改您的信息以使其更准确有效。您可以在游戏中通过点击个人头像进入个人中心，进而查询或修改您的昵称等信息，具体请根据个人中心界面的指引进行。</p>
        <p>
          如上述功能不能满足您的要求，您可以通过拨打我们的客服电话400-903-1158  或通过游戏产品中的客服功能与我们联系进行查询、修改、删除个人信息，我们将在15个工作日内核实并处理您反馈的问题。</p>

        <p>（二）删除</p>
        <p>
          您有权删除您的个人信息。我们承诺对于已收集且完成相应目的个人信息会主动、及时删除。对于以下情况，您可以直接要求我们删除您的个人信息，但已做匿名化处理或法律法规另有约定的除外：</p>
        <p>1）当我们违反法律法规处理您的个人信息时；</p>
        <p>2）当我们未经您的同意收集、使用您的个人信息时；</p>
        <p>
          3）当我们违反与您的约定（包括但不限于该《隐私政策》及我们与您签订的其他协议中关于个人信息的约定），处理您的个人信息时；</p>
        <p>4）当您注销了您的账号时；</p>
        <p>5）当我们终止服务及运营时。</p>
        <p>
          上述应当删除的信息若未满相关法律法规规定的保存期限，或由于技术原因暂时或一定期限内无法删除的，我们承诺将立即停止对该类信息一切非必要的处理行为，仅对该类信息进行存储和采取必要的安全措施。</p>


        <p>（三）公开与分享</p>
        <p>
          公司的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，例如，您在公司服务中所上传或发布的信息、您对其他人上传或发布的信息作出的回应，通过电子邮件或在公司服务中不特定用户可见的公开区域内上传或公布您的个人信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息。</p>

        <p>（四）注销</p>
        <p>
          在符合我们单项服务的服务协议约定条件及国家相关法律法规规定的情况下，您的该项我们服务账号可能被注销或删除。当账号注销或被删除后，与该账号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理。</p>
        <p>
          若您希望主动注销账号，可点击游戏中的注销入口，根据游戏内提示完成相关步骤后即可注销，我们承诺仅为注销设置必要合理的验证步骤。</p>
        <p>您也可以通过以下途径申请注销账号：</p>
        <p>1、点击设置按钮，再点击账号设置，进行游戏账号注销。</p>
        <p>
          2、拨打我们的客服电话400-903-1158 或通过游戏设置中客服功能与我们的在线客服联系，我们将在十五个工作日内核实并回复您的注销请求。</p>


        <p>（五）改变您授权同意的范围</p>
        <p>您可以通过设备本身操作系统变更或撤回部分已授权权限；</p>
        <p>1、卸载游戏重新进行安装进行授权；</p>
        <p>
          2、拨打我们的客服电话400-903-1158或通过游戏设置中的客服功能与我们的在线客服联系。我们将在十五个工作日内核实并处理您反馈的问题。</p>


        <p>（六）有关敏感信息的提示</p>
        <p>某些信息因其特殊性可能被认为是敏感信息，例如<strong>您的种族、宗教、个人健康和医疗信息等，以及身份证明文件、个人生物识别信息、财产信息、行踪轨迹、以及未满14周岁未成年人的所有信息等。涉及到您的敏感信息时，我们会在收集前，单独征得您的同意。请注意，您在我们服务中所提供、上传或发布的内容和信息（例如有关您社交活动的照片或信息），可能会泄露您的敏感信息。您需要谨慎地考虑，是否使用我们服务披露您的敏感信息。
          您同意您的敏感信息按本《隐私政策》所述的目的和方式来处理。</strong></p>


        <p>（七）响应您的上述请求</p>
        <p>
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在十五个工作日内做出答复。</p>

        <p>
          对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们有权予以拒绝。</p>


        <p>在以下情形中，我们将无法响应您的请求：</p>
        <p>（1）与个人信息控制者履行法律法规规定的义务相关的；</p>
        <p>（2）与国家安全、国防安全直接相关的；</p>
        <p>（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>（4）与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
        <p>（5）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
        <p>（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p>（7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
        <p>（8）涉及商业秘密的。</p>


        <p><strong>七、第三方服务</strong></p>
        <p>如您在我们平台上使用由第三方提供的服务时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式）。目前公司产品中包含的第三方
          SDK目录，请点击
          <router-link to="/thirdPartySdk">《第三方sdk目录》</router-link>
          查看，第三方SDK会根据其自身隐私政策所述标准存储、使用、处理和保护您的个人信息，本隐私政策不能代替上述第三方的隐私政策，强烈建议您在使用上述第三方SDK服务前先行阅读其相关隐私政策。公司服务可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：
        </p>

        <p>
          （一）您可利用“分享”键将某些内容分享到第三方平台，或您可利用第三方服务登录我们的平台。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能；</p>

        <p>（二）我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站；</p>

        <p>
          （三）其他接入第三方服务的情形。您使用第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本《隐私政策》）约束，您需要仔细阅读其条款。本《隐私政策》仅适用于我们所收集的任何信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，而我们对任何第三方使用由您提供的信息不承担任何责任。</p>

        <p><strong>八、个人信息跨境转移</strong></p>
        <p>
          <strong>原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内（不包括台湾、香港及澳门地区）。</strong>
        </p>

        <p>
          <strong>由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的单独授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。</strong>
        </p>


        <p><strong>此类管辖区可能设有不同的数据保护法，或者未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</strong>
        </p>

        <p><strong>九、未成年人保护</strong></p>
        <p>（一）关于我们对未成年人保护的政策详见
          <router-link to="/childProtection">《未成年人隐私保护指引》</router-link>
        </p>


        <p>
          （二）我们高度重视未成年人个人信息的保护问题，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称"监护人"）的同意。我们在收集年满14周岁未成年人的个人信息前，将征得未成年人或其监护人的明示同意；不满14周岁的，将征得其监护人的明示同意。未成年人用户及其监护人理解并确认，如您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能导致的全部法律责任。</p>
        <p>我们也将根据国家相关法律法规的规定保护未成年人个人信息的保密性及安全性。</p>


        <p>（三）我们将按照国家防沉迷政策要求，<strong>通过实名身份等信息校验判断相关账号的实名信息是否为未成年人，进而决定是否将此账号纳入到防沉迷体系中，通过启用防沉迷系统保护未成年人（包括未成年人）的合法权益。</strong>
        </p>


        <p>
          （四）我们鼓励父母或监护人指导未成年人使用公司服务。我们建议未成年人鼓励他们的父母或监护人阅读本政策，并建议未成年人在提交信息之前寻求父母或监护人的同意和指导。</p>


        <p>
          （五）若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本政策“争议解决及联系方式”章节中的联系方式与我们联系。</p>


        <p>（六）公司游戏专门为未成年人提供青少年模式，监护人应为未成年人选择使用青少年模式。</p>


        <p>（七）未成年人用户特别提示</p>
        <p>
          （1）未成年人使用公司游戏及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯；</p>
        <p>（2）青少年用户必须遵守《全国青少年网络文明公约》；</p>
        <p>（3）要善于网上学习，不浏览不良信息；</p>
        <p>（4）要诚实友好交流，不侮辱欺诈他人；</p>
        <p>（5）要增强自护意识，不随意约会网友；</p>
        <p>（6）要维护网络安全，不破坏网络秩序；</p>
        <p>（7）要有益身心健康，不沉溺虚拟时空。</p>


        <p>
          （八）为更好地保护未成年人隐私权益，公司特别提醒您慎重发布包含未成年人素材的内容，一经发布，即视为您已获得权利人同意在游戏服务及相关服务展示未成年人的肖像、声音等信息，且允许公司依据本协议使用、处理该等与未成年人相关的内容。
        </p>


        <p>（九）监护人特别提示</p>
        <p>
          （1）如您的被监护人使用公司游戏及相关服务的，您作为监护人应指导并监督被监护人的注册和使用行为，如您的被监护人申请注册游戏账号，公司将有权认为其已取得您的同意；</p>
        <p>
          （2）您的被监护人在使用公司游戏及相关服务时可能使用购买产品或服务等功能。您作为监护人，请保管好您的支付设备、支付账户及支付密码等，以避免被监护人在未取得您同意的情况下通过您的游戏账号使用购买等消费功能。</p>


        <p><strong>十、通知和修订</strong></p>
        <p>
          我们可能适时修改本《隐私政策》的条款，该等修改构成本《隐私政策》的一部分。对于重大变更，我们会提供更显著的通知，您可以选择停止使用公司服务；在该种情况下，如您仍然继续使用公司服务的，即表示同意受经修订的本《隐私政策》的约束。任何修改都会将您的满意度置于首位。我们鼓励您在每次使用公司服务时都查阅我们的隐私政策。</p>
        <p>
          我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您无法取消这些与服务有关、性质不属于推广的公告。</p>
        <p>最后，您对您的账号和密码信息负有保密义务。任何情况下，请小心妥善保管。</p>


        <p><strong>十一、争议解决及联系方式</strong></p>
        <p>
          （一）本政策的订立、履行、解释及争议的解决均适用中华人民共和国法律并排除其他一切冲突法的适用。如您就本政策的内容或其执行发生任何争议（包括但不限于合同或者其他财产性权益纠纷），应及时联系我们友好协商解决。如协商不成时，您同意将争议提交至上海市浦东新区人民法院。</p>


        <p>
          （二）如您有关于网络信息安全的投诉和举报，或您对本《隐私政策》、您的信息的相关事宜有任何问题、意见或建议，以及有关本声明或我们的隐私措施的问题请发送邮件至客服邮箱【shichang@gg.com】联系我们，我们的公司地址是【中国（上海）自由贸易试验区芳春路400号1幢3层】。</p>


        <p>【注意】</p>
        <p>
          根据《中华人民共和国网络安全法》、《中共华人民共和国数据安全法》、《中华人民共和国个人信息保护法》、《信息安全技术个人信息安全规范》及其他相关法律法规和技术规范，为保障网络游戏用户（下称“用户”）的隐私权、规范对网络游戏用户个人信息的利用，特制定本政策。请用户仔细阅读以下全部内容。如用户不同意本政策的任意内容，请不要注册或使用我们服务。如用户进入注册程序，即表示用户与我们已达成协议，自愿接受本政策的所有内容。此后，用户不得以未阅读本政策的内容作任何形式的抗辩。</p>

        <p>
          尊重用户个人隐私是我们的一项基本政策。“隐私”是指用户在注册账号时提供给我们的个人身份信息，包括但不限于用户注册资料中的姓名、个人有效身份证件号码、联系方式、家庭住址等。我们一贯积极地采取技术与管理等合理措施保障用户账号的安全、有效；我们将善意使用收集的信息，采取各项有效且必要的措施以保护您的隐私安全，并使用商业上合理的安全技术措施来保护您的隐私不被未经授权的访问、使用或泄漏。</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  mounted() {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/privacy.scss";
</style>
